document.addEventListener("DOMContentLoaded", function () {
  // Smooth Scrolling
  if ("SmoothScroll" in window) {
    new SmoothScroll('a[href*="#"]:not(.popup-link)');
  }

  // Initialize AOS
  if (typeof AOS !== "undefined") {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: "ease", // Animation easing
      once: true, // Whether animation should happen only once
      offset: 120, // Offset from the original trigger point
    });
  } else {
    console.warn("AOS is not defined. Ensure it is enqueued properly.");
  }

  // Accessibility Enhancements
  enhanceAccessibility();

  // Popup Handling
  initializePopups();

  // Site Banner Close
  initSiteBannerClose();

  // Media Page Accordion
  initMediaPageAccordion();
});

/**
 * Enhance link accessibility by adding screen reader text.
 */
function enhanceAccessibility() {
  const accessibilityRules = [
    { selector: 'a[href$=".pdf"]', text: "(opens PDF)" },
    { selector: 'a[href^="mailto:"]', text: "(opens default email app)" },
    { selector: 'a[href^="tel:"]', text: "(opens telephone link)" },
    { selector: 'a[target="_blank"]', text: "(opens in a new tab)" },
  ];

  accessibilityRules.forEach((rule) => {
    document.querySelectorAll(rule.selector).forEach((link) => {
      const srSpan = document.createElement("span");
      srSpan.className = "sr-only";
      srSpan.textContent = rule.text;
      link.appendChild(srSpan);
    });
  });

  document.querySelectorAll("input.wpforms-field-required").forEach((input) => {
    input.setAttribute("aria-required", "true");
  });
}

/**
 * Initialize popup functionality.
 */
function initializePopups() {
    // MagnificPopup initialization
    if (typeof jQuery !== "undefined") {

      jQuery(".iframe-popup-link").magnificPopup({
  
        type: "iframe",
  
        midClick: true,
        });

        jQuery('.popup-link').magnificPopup({
            type: 'inline',
            midClick: true
        });
      } else {

        console.warn("jQuery is not defined. Ensure it is enqueued properly.");
    
      }


    // ID Preservation Mutation Observer
    const popupObserver = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
          if (mutation.type === 'attributes') {
              const target = mutation.target;
              if (target.hasAttribute('data-scroll-id')) {
                  const originalId = target.getAttribute('data-scroll-id');
                  target.removeAttribute('data-scroll-id');
                  target.id = originalId;
              }
          }
      });
    }); 

    // Handle clicks on anchor links for popups
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function(event) {
            const href = this.getAttribute('href');
            if (href && href.startsWith('#')) {
                const popupId = href.substring(1);
                if (validPopupIds.includes(popupId)) {
                    event.preventDefault();
                    showPopup(popupId);
                }
            }
        });
    });

    // Observe all basic popups
    document.querySelectorAll('.basic-popup').forEach(popup => {
      popupObserver.observe(popup, { 
          attributes: true, 
          attributeFilter: ['data-scroll-id', 'id'] 
      });
    });

    // Popup close functionality
    document.querySelectorAll('.popup-close, .popup-overlay').forEach(element => {
        element.addEventListener('click', () => {
            const popup = element.closest('.basic-popup');
            if (popup) hidePopup(popup);
        });
    });
}


/**
 * Show a specific popup
 * @param {string} popupId - ID of the popup to show
 */
function showPopup(popupId) {
  const popup = document.getElementById(popupId);
  if (popup) {
      popup.style.display = 'block';
      popup.id = popupId;  // Explicitly set the ID
  }
}

/**
* Hide a popup
* @param {HTMLElement} popup - Popup element to hide
*/
function hidePopup(popup) {
  if (popup) {
      popup.style.display = 'none';
  }
}


/**
 * Initialize site banner close functionality.
 */
function initSiteBannerClose() {
  const siteBanner = document.getElementById("site-banner");
  const closeBanner = siteBanner?.querySelector(".close-btn");

  if (closeBanner) {
    closeBanner.addEventListener("click", () => {
      siteBanner.classList.add("closed");
    });
  }
}

/**
 * Initialize accordion on the media page.
 */
function initMediaPageAccordion() {
  const mediaPage = document.querySelector(".page-id-15569");
  const firstAccordionItem = mediaPage?.querySelector(".accordion-item");

  if (firstAccordionItem) {
    firstAccordionItem.classList.add("active");
  }
}
