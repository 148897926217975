// Enable AOS - documentation: https://michalsnik.github.io/aos/

/** IMPORTANT **
 *
 * Use AOS declarations in class="" instead of data-aos="" - this is added to make AOS.js friendly for bootstrap gutenberg blocks.
 *
 * e.g. class="data-aos_fade-in" replaces data-aos="fade-in"
 *
 * the element MUST have class "aos-animate" added to trigger
 *
 */

// Function to check if a string matches a specific pattern
function isMatch(str) {
  let ret = null;
  const patterns = [
    "data-aos_",
    "data-aos-delay_",
    "data-aos-duration_",
    "data-aos-easing_",
  ];

  patterns.forEach(function (pattern) {
    if (String(str).includes(pattern)) {
      ret = str.split("_");
      // Exit iteration early
    }
  });

  return ret;
}

// On DOMContentLoaded
document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll(".aos-animate").forEach(function (element) {
    const classList = element.className.split(" "); // Get class names as an array

    classList.forEach(function (className) {
      const matchResult = isMatch(className);
      if (matchResult !== null) {
        element.setAttribute(matchResult[0], matchResult[1]);
      }
    });
  });

  // Initialize AOS (if AOS library is loaded)
  if (typeof AOS !== "undefined") {
    AOS.init({
      duration: 900,
      offset: 200,
    });
  }
});
