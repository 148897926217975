// Selecting elements
const header = document.getElementById("header"); // Header
const mainMenu = document.getElementById("main-menu"); // Main Menu
const siteMenu = document.getElementById("site-menu"); // Site Menu Container
const browserWindow = window; // Window Height

// Menu Toggle Functionality
document.querySelector(".menu-toggle").addEventListener("click", function () {
  const pullMenu = document.getElementById("pull-menu");
  pullMenu.classList.toggle("fixed");
  pullMenu.classList.toggle("is-active");
  siteMenu.classList.toggle("is-active");

  if (siteMenu.classList.contains("is-active")) {
    this.setAttribute("aria-expanded", "true");
    this.querySelector(".sr-only").innerHTML = "close main navigation menu";
    siteMenu.setAttribute("aria-hidden", "false");
    siteMenu
      .querySelectorAll("a:not(#mobile-branding)")
      .forEach((link) => link.setAttribute("tabindex", "0"));
  } else {
    this.setAttribute("aria-expanded", "false");
    this.querySelector(".sr-only").innerHTML = "open main navigation menu";
    siteMenu.setAttribute("aria-hidden", "true");
    siteMenu
      .querySelectorAll("a:not(#mobile-branding)")
      .forEach((link) => link.setAttribute("tabindex", "-1"));
  }
});

/**
 * Sub-menu functionality
 */
document
  .querySelectorAll("header .menu-item-has-children > a")
  .forEach((link) => {
    link.addEventListener("mouseenter", () =>
      link.setAttribute("aria-expanded", "true"),
    );
    link.addEventListener("mouseleave", () =>
      link.setAttribute("aria-expanded", "false"),
    );
  });

/**
 * Mega Menu Functionality
 */
document
  .querySelectorAll(".mega-menu-navigation > li.has-mega-menu > .mega-menu")
  .forEach((menu) => {
    const megaMenuID = menu.id;
    menu.setAttribute("aria-hidden", "true");
    const parentLink = menu.previousElementSibling;
    parentLink.setAttribute("role", "button");
    parentLink.setAttribute("aria-controls", megaMenuID);
    parentLink.setAttribute("aria-expanded", "false");
    parentLink.setAttribute("aria-haspopup", "true");
  });

document
  .querySelectorAll(".mega-menu-navigation > li.has-mega-menu")
  .forEach((menuItem) => {
    menuItem.addEventListener("mouseenter", (e) => {
      e.stopPropagation();
      menuItem.classList.add("active");
      const megaMenu = menuItem.querySelector(".mega-menu");
      megaMenu.style.display = "block";
      megaMenu.classList.add("active");
      megaMenu.setAttribute("aria-hidden", "false");

      menuItem.querySelector("a").setAttribute("aria-expanded", "true");

      // Hide siblings
      menuItem.parentElement
        .querySelectorAll("li.has-mega-menu")
        .forEach((sibling) => {
          if (sibling !== menuItem) {
            sibling.classList.remove("active");
            const siblingMenu = sibling.querySelector(".mega-menu");
            siblingMenu.style.display = "none";
            siblingMenu.classList.remove("active");
            siblingMenu.setAttribute("aria-hidden", "true");
            sibling.querySelector("a").setAttribute("aria-expanded", "false");
          }
        });
    });

    menuItem.addEventListener("mouseleave", () => {
      menuItem.classList.remove("active");
      const megaMenu = menuItem.querySelector(".mega-menu");
      megaMenu.style.display = "none";
      megaMenu.classList.remove("active");
      megaMenu.setAttribute("aria-hidden", "true");
      menuItem.querySelector("a").setAttribute("aria-expanded", "false");
    });
  });

/**
 * Site Search Toggle
 */
const siteSearch = document.getElementById("site-search");
document.querySelector(".site-search").addEventListener("click", (e) => {
  e.preventDefault();
  siteSearch.classList.toggle("active");

  if (siteSearch.classList.contains("active")) {
    siteSearch.querySelector("input[type=text]").focus();
  }
});

/**
 * Language Setup
 */
const wpmlLangSpan = document.querySelector(".wpml-ls-menu-item > a > span");
if (wpmlLangSpan) {
  const lang = wpmlLangSpan.getAttribute("lang");
  wpmlLangSpan.innerHTML = lang;
}

/**
 * Mobile Menu Functionality
 */
document
  .querySelectorAll("#main-menu-mobile > li.menu-item-has-children > a")
  .forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      const submenu = link.nextElementSibling;
      const parent = link.parentElement;

      // Close other submenus
      document
        .querySelectorAll("#main-menu-mobile > li > ul.sub-menu")
        .forEach((otherSubmenu) => {
          if (otherSubmenu !== submenu) {
            otherSubmenu.style.display = "none";
            otherSubmenu.parentElement.classList.remove("sub-menu-active");
            otherSubmenu.previousElementSibling.setAttribute(
              "aria-expanded",
              "false",
            );
          }
        });

      // Toggle current submenu
      if (parent.classList.toggle("sub-menu-active")) {
        submenu.style.display = "block";
        link.setAttribute("aria-expanded", "true");
      } else {
        submenu.style.display = "none";
        link.setAttribute("aria-expanded", "false");
      }
    });
  });

// Second Level Menu
document
  .querySelectorAll(
    "#main-menu-mobile > li > ul > li.menu-item-has-children > a",
  )
  .forEach((link) => {
    link.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();

      const submenu = link.nextElementSibling;
      const parent = link.parentElement;

      // Close other submenus
      document
        .querySelectorAll("#main-menu-mobile > li > ul > li > ul.sub-menu")
        .forEach((otherSubmenu) => {
          if (otherSubmenu !== submenu) {
            otherSubmenu.style.display = "none";
            otherSubmenu.parentElement.classList.remove("sub-menu-active");
            otherSubmenu.previousElementSibling.setAttribute(
              "aria-expanded",
              "false",
            );
          }
        });

      // Toggle current submenu
      if (parent.classList.toggle("sub-menu-active")) {
        submenu.style.display = "block";
        link.setAttribute("aria-expanded", "true");
      } else {
        submenu.style.display = "none";
        link.setAttribute("aria-expanded", "false");
      }
    });
  });
